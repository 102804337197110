<template>
  <v-theme-provider>
    <base-section
      id="testimony"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <div 
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center">
            <base-section-heading title="Terima Kasih Telah Percaya Pada Istpro" class="primary--text"/>
            </div>
            <base-body space="0">
				<div 
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    data-aos-anchor-placement="top-center">
               <v-container fluid v-if="$vuetify.breakpoint.mdAndUp">
					<v-row dense>
					<v-col
						v-for="data in posts"
						:key="data.testimony_id"
						md="4"
						xs="12"
						cols="12"
					>
						<!-- @click="Detail(data.testimony_id)" -->
						<v-sheet outlined color="primary" rounded>
							<v-card
							outlined
							height="315px"
							>
							<div class="text-center mt-2">
							<v-avatar size="80">
							<v-img
								:src="require(`@/assets/${data.testimony_src_url}`)"
								class="white--text align-end"
								lazy-loading
								:alt="data.testimony_name"
							>
							</v-img>
							</v-avatar>
							</div>
							<div class="text-center">
								{{data.testimony_name}}<br>
								{{data.testimony_title}}
							</div>
							<v-card-subtitle v-html="data.testimony_content" class="body-2"></v-card-subtitle>
							</v-card>
						</v-sheet>
					</v-col>
					<v-col class="text-center mt-3">
					<base-btn
						:block="$vuetify.breakpoint.smAndDown"
						color="primary"
						large
						outlined
						to="berita-lainnya"
						v-if="$route.name === 'beranda' || $route.name === ''"
						class = "text-capitalize"
					>
						Lainnya
					</base-btn>
					</v-col>
					</v-row>
			   </v-container>
			   <v-container fluid v-else>
			   <v-carousel
					cycle
					hide-delimiters
				>
			   <v-carousel-item
				v-for="data in posts"
                :key="data.testimony_id"
				>
                <v-row dense>
                  <v-col
                    md="4"
                    xs="12"
                    cols="12"
                  >
                    <!-- @click="Detail(data.testimony_id)" -->
					<v-sheet outlined color="primary" rounded>
						<v-card
						outlined
						height="315px"
						>
						<div class="text-center mt-2">
						<v-avatar size="80">
						<v-img
							:src="require(`@/assets/${data.testimony_src_url}`)"
							class="white--text align-end"
							lazy-loading
							:alt="data.testimony_name"
						>
						</v-img>
						</v-avatar>
						</div>
						<div class="text-center">
							{{data.testimony_name}}<br>
							{{data.testimony_title}}
						</div>
						<v-card-subtitle v-html="data.testimony_content" class="body-2"></v-card-subtitle>
						</v-card>
					</v-sheet>
                  </v-col>
                </v-row>
				</v-carousel-item>
			  </v-carousel>
			  <v-row style="margin-top:-170px">
				<v-col class="text-center">
					<base-btn
						:block="$vuetify.breakpoint.smAndDown"
						color="primary"
						large
						outlined
						to="berita-lainnya"
						v-if="$route.name === 'beranda' || $route.name === ''"
						class = "text-capitalize"
					>
						Lainnya
					</base-btn>
				</v-col>
			   </v-row>
              </v-container>
			  </div>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNewsLatest',
    data: () => ({
            posts: [],
        }),
    created () {
        this.List()
        },
    methods: {
        List () {
          this.loading  = true
          this.posts = [
                        {'testimony_id':'1','testimony_name':'Andrea Sultan','testimony_title':'Kepala Pemasaran Properti','testimony_src_url':'testimony-1.png','testimony_content':'Terima kasih ISTPRO, sudah membersamai setiap proyek pembangunan kami selama 3 tahun hingga sekarang dan membantu setiap proses klaim sampai tuntas, kala itu hingga 500 Juta. Kami tetap setia menggunakan jasa ISTPRO karena di sini bukan hanya membeli proteksi tetapi juga diarahkan asuransi yang sesuai.'},
                        {'testimony_id':'2','testimony_name':'Ruth Diana','testimony_title':'Direktur Utama PT Cargo','testimony_src_url':'testimony-2.png','testimony_content':'Kami sangat puas dengan pelayanan ITSPRO, selalu siap sedia dengan pelayanan dan penyampaian informasi yang mudah dimengerti dan sesuai yang kami inginkan. Sudah hampir 5 tahun ITSPRO membersamai setiap aktivitas pengiriman kami.'},
                        {'testimony_id':'3','testimony_name':'Bachtiar Adi','testimony_title':'Karyawan Swasta','testimony_src_url':'testimony-3.png','testimony_content':'Tempat kerja saya menggunakan jasa penjaminan dari ISTPRO. Ternyata di sini juga menyediakan asuransi kendaraan dan tempat tinggal. Jadi, saya dan keluarga percayakan asuransi rumah dan mobil pada ISTPRO. Terima kasih ISTPRO telah memberikan asuransi terbaik dan membantu klaim saya kala itu hingga beres'},
                        ]
        },
        FormatDateFullOnlyDate (date) {
          return this.$functions.FormatDateFullOnlyDate(date)
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Detail (url) {
          this.$router.push({name: 'berita-detail', params: { url: url }})
        },
        Share (url) {
          let paramurl = this.$functions.AppURL() +'/berita-detail/' + url
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        }
    },
  }
</script>
